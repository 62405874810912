import { template as template_8a22e4b2399e4213950c490072e514cb } from "@ember/template-compiler";
const WelcomeHeader = template_8a22e4b2399e4213950c490072e514cb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
